
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import UserService from "@/core/services/UserService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { roles as role } from "@/store/stateless/store";

interface AddUserBodyParam {
  name: any;
  email: any;
  official_email: any;
  role_id: any;
  is_round_robin: any;
  password: any;
  password_confirmation: any;
  policy_agent_id: any;
  assign_en_leads: any;
  assign_ar_leads: any;
  is_new: any;
  is_renewal: any;
  is_llr: any;
  is_all_deals: any;
  is_renewal_deals: any;
  assign_car: any;
  assign_health: any;
  assign_misc: any;
  status: any;
  skip_round: any;
  is_both: any;
  team_leader: any;
  other_mobile_number?: any;
  office_mobile_number?: any;
  roles?: any;
  is_underwriter:any;
  misc_insurance_types:any
}

export default defineComponent({
  name: "Add-User",
  props: {},

  data() {
    return {};
  },
  setup() {
    const schema = yup.object({
      Name: yup.string().required().min(3),
      Email: yup.string().required().email(),
      official_email: yup.string().required().email(),
      Password: yup.string().required().min(8),
      ConfirmPassword: yup.string().required().min(8)
    });

    useForm({
      validationSchema: schema
    });

    const { value: name, errorMessage: NameError } = useField("Name");
    const { value: email, errorMessage: emailError } = useField("Email");
    const { value: official_email, errorMessage: official_emailError } = useField("official_email");
    const { value: password, errorMessage: passwordError } =
      useField("Password");

    const { value: confirmPassword, errorMessage: confirmPasswordError } =
      useField("ConfirmPassword");

    const rules = ref({
      name: {
        show: false,
        message: "Please enter name"
      },

      email: {
        show: false,
        message: "Please enter valid email address format"
      },
      official_email: {
        show: false,
        message: "Please enter valid official email address"
      },
      password: {
        show: false,
        message: "Please enter password"
      },
      confirm_password: {
        show: false,
        message: "Please enter confirm password"
      },
      password_match: {
        show: false,
        message: "Password do not match"
      },
      role: {
        show: false,
        message: "Please select role"
      },
      underwritter: {
        show: false,
        message: "Please select underwriter"
      },
      roundRobin: {
        show: false,
        message: "Please select Round Robin Setting"
      },
      assign_leads_of: {
        show: false,
        message: "Please select leads assignment"
      },
      assign_misc_insurance_types: {
        show: false,
        message: "Please select any one insurance type"
      },
      assign_language: {
        show: false,
        message: "Please select language"
      },
      agent_type: {
        show: false,
        message: "Please select agent type"
      },
      team_leader: {
        show: false,
        message: "Please select team leader"
      },
      other_mobile_number: {
        show: false,
        message: "Other mobile number must not less than 4 character"
      },
      office_mobile_number: {
        show: false,
        message: "Office mobile number must not less than 4 character"
      },
      roles: {
        show: false,
        message: "Roles is required"
      }
    });

    const store = useStore();
    const route = useRoute();
    let roleValue = ref<string>("0");
    let underwritter = ref<string>("0");
    let teamLeader = ref<string>("0");
    let roundRobinValue = ref<string>("0");
    let renewalValue = ref<string>("");
    let selfDeal = ref(0);
    let is_llr = ref(0);
    let isFormValid = ref<boolean>(true);
    let disableRenewalDom = ref<boolean>(true);
    let assign_leads_of_count = ref(0);
    let assign_language_count = ref(0);
    let isMiscCheck = ref(0);
    let addUserBodyParam = ref<AddUserBodyParam>({
      name: "",
      email: "",
      official_email:"",
      role_id: 0,
      password: "",
      password_confirmation: "",
      is_round_robin: "0",
      policy_agent_id: 0,
      assign_en_leads: 0,
      assign_ar_leads: 0,
      is_new: 0,
      is_renewal: 0,
      is_llr: 0,
      is_all_deals: 0,
      is_renewal_deals: 0,
      assign_car: 0,
      assign_health: 0,
      assign_misc: 0,
      status: 1,
      skip_round: 0,
      is_both: 0,
      team_leader: 0,
      other_mobile_number: "",
      office_mobile_number: "",
      roles: [],
      is_underwriter:0,
      misc_insurance_types: [],
    });

    onMounted(() => {
      if(!role.users.includes(role.userId())) window.location.href = '/#/404'
      
      toFirst()
      
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Add User", ["Users"]);

      UserService.getRoles();
      UserService.getUnderWriters({'assign':'all'});
      UserService.getTeamLeaders();
    });

    const onRoleChange = (event) => {
      addUserBodyParam.value.role_id = event;
      roleValue.value = event;
      rules.value.role.show = false
      // if(addUserBodyParam.role_id == 2)
      // {
      //   //  addShowOptions.isShowOptions = true;
      // }
    };

    const onChangeStatus = (event) => {
      addUserBodyParam.value.status = event.target.value;
    };

    const onChangePolicyAgent = (event) => {
      addUserBodyParam.value.policy_agent_id = event
      rules.value.underwritter.show = false
    };
    const onChangeTeamLeader = (event) => {
      addUserBodyParam.value.team_leader = event
      rules.value.team_leader.show = false
    };

    const onChangeEng = (event) => {
      if (event.target.checked) {
        addUserBodyParam.value.assign_en_leads = event.target.value;
        assign_language_count.value = assign_language_count.value + 1;
        checkLeadsLanguage();
      } else {
        addUserBodyParam.value.assign_en_leads = 0;
        assign_language_count.value = assign_language_count.value - 1;
        checkLeadsLanguage();
      }
    };

    const onChangeAr = (event) => {
      if (event.target.checked) {
        addUserBodyParam.value.assign_ar_leads = event.target.value;
        assign_language_count.value = assign_language_count.value + 1;
        checkLeadsLanguage();
      } else {
        addUserBodyParam.value.assign_ar_leads = 0;
        assign_language_count.value = assign_language_count.value - 1;
        checkLeadsLanguage();
      }
    };
    const checkLeadsLanguage = () => {
      rules.value.assign_language.show =
        assign_language_count.value > 0 ? false : true;
    };
    const onChangeNew = (event) => {
      addUserBodyParam.value.is_new = event.target.value;
      addUserBodyParam.value.is_both = 0;
      addUserBodyParam.value.is_llr = 0;
      addUserBodyParam.value.is_renewal = 0;
      renewalValue.value = "0";
      selfDeal.value = 2;
      disableRenewalDom.value = true;
      is_llr.value = 0;
      rules.value.agent_type.show = false;
    };

    const onChangeRenewal = (event) => {
      // if (event.target.checked) {
      disableRenewalDom.value = false;
      addUserBodyParam.value.is_renewal = event.target.value;
      renewalValue.value = event.target.value;
      addUserBodyParam.value.is_both = 0;
      addUserBodyParam.value.is_new = 0;
      rules.value.agent_type.show = false;
      // } else {
      //   addUserBodyParam.value.is_renewal = 0;
      //   renewalValue.value = "0";
      //   addUserBodyParam.value.is_both = 0;
      // }
    };

    const onChangeBoth = (event) => {
      console.log(addUserBodyParam.value.is_both);
      // if (event.target.checked) {
      disableRenewalDom.value = false;
      addUserBodyParam.value.is_renewal = 0;
      addUserBodyParam.value.is_new = 0;
      // addUserBodyParam.is_both = event.target.value;
      renewalValue.value = "1";
      rules.value.agent_type.show = false;
      // } else {
      //   addUserBodyParam.is_new = 0;
      //   addUserBodyParam.is_renewal = 0;
      //   addUserBodyParam.is_both = 0;
      //   renewalValue.value = "0";
      // }
    };
    const onChangeLlr = (event) => {
      if (event.target.checked) {
        addUserBodyParam.value.is_llr = event.target.value;
        addUserBodyParam.value.is_new = 0;
        addUserBodyParam.value.is_renewal = 1;
      } else {
        addUserBodyParam.value.is_llr = 0;
      }
    };

    const onChangeDeals = (event) => {
      // addUserBodyParam.value.is_renewal_deals = event.target.value;
      if (event.target.value == 2) {
        // addUserBodyParam.value.is_new = "1";
        // addUserBodyParam.value.is_both = 0;
        // addUserBodyParam.value.is_llr = 0;
        // addUserBodyParam.value.is_renewal = 0;
        // renewalValue.value = "0";
        // selfDeal.value = 2;
        // is_llr.value = 0;
        addUserBodyParam.value.is_all_deals = "0";
      }
      if (event.target.value == 1) {
        addUserBodyParam.value.is_all_deals = "1";
      }
    };

    const onInRoundRobin = (event) => {
      addUserBodyParam.value.is_round_robin = event.target.value;
      if (addUserBodyParam.value.is_round_robin == "0") {
        selfDeal.value = 3;
        addUserBodyParam.value.is_new = 0;
      } else {
        selfDeal.value = 2;
        addUserBodyParam.value.is_new = 1;
      }
      roundRobinValue.value = event.target.value;
    };

    const onChangeAssignedLeads = (event:number) => {
      addUserBodyParam.value.assign_car = 0;
      addUserBodyParam.value.assign_health = 0;
      addUserBodyParam.value.assign_misc = 0;
      switch(event) {
        case 1: addUserBodyParam.value.assign_car = 1; isMiscCheck.value = 0; break;
        case 2: addUserBodyParam.value.assign_health = 1; isMiscCheck.value = 0; break;
        case 3: addUserBodyParam.value.assign_misc = 1; isMiscCheck.value = 1; break;
        default: return 0;
      }
      rules.value.assign_leads_of.show = false;
    };

    const onChangeCar = (event) => {
      // console.log(event.target.check);
      if (event.target.checked) {
        addUserBodyParam.value.assign_car = event.target.value;
        assign_leads_of_count.value = assign_leads_of_count.value + 1;
        checkLeadsOf();
      } else {
        addUserBodyParam.value.assign_car = 0;

        assign_leads_of_count.value = assign_leads_of_count.value - 1;
        checkLeadsOf();
      }
    };

    const onChangeHealth = (event) => {
      if (event.target.checked) {
        addUserBodyParam.value.assign_health = event.target.value;
        assign_leads_of_count.value = assign_leads_of_count.value + 1;
        checkLeadsOf();
      } else {
        addUserBodyParam.value.assign_health = 0;
        assign_leads_of_count.value = assign_leads_of_count.value - 1;
        checkLeadsOf();
      }
    };

    const onChangeMisc = (event) => {
      if (event.target.checked) {
        addUserBodyParam.value.assign_misc = event.target.value;
        assign_leads_of_count.value = assign_leads_of_count.value + 1;
        checkLeadsOf();
      } else {
        addUserBodyParam.value.assign_misc = 0;
        assign_leads_of_count.value = assign_leads_of_count.value - 1;
        checkLeadsOf();
      }
    };
    
    const checkLeadsOf = () => {
      rules.value.assign_leads_of.show =
        assign_leads_of_count.value > 0 ? false : true;
    };
    const checkPassword = () => {
      if (
        confirmPassword.value != undefined &&
        confirmPassword.value != password.value
      ) {
        rules.value.password_match.show = true;
      } else {
        rules.value.password_match.show = false;
      }
    };
    const resetValidation = () => {
      rules.value.name.show = false;
      rules.value.email.show = false;
      rules.value.official_email.show = false;
      rules.value.office_mobile_number.show = false;
      rules.value.other_mobile_number.show = false;
      rules.value.password.show = false;
      rules.value.confirm_password.show = false;
      rules.value.role.show = false;
    };

    const validateAddUser = () => {
      var regX =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
      if (
        addUserBodyParam.value.name == "" ||
        addUserBodyParam.value.name == undefined
      ) {
        rules.value.name.show = true;
        isFormValid.value = false;
      }
      if (
        addUserBodyParam.value.email == "" ||
        addUserBodyParam.value.email == undefined
      ) { 
        rules.value.email.show = true;
        isFormValid.value = false;
      }
      if (!regX.test(addUserBodyParam.value.official_email))  {
        rules.value.official_email.show = true;
        isFormValid.value = false;
      }
      if (
        addUserBodyParam.value.password == "" ||
        addUserBodyParam.value.password == undefined
      ) {
        rules.value.password.show = true;
        isFormValid.value = false;
      }
      if (
        addUserBodyParam.value.other_mobile_number != "" &&
        addUserBodyParam.value.other_mobile_number < 9999
      ) {
        rules.value.other_mobile_number.show = true;
        isFormValid.value = false;
      }
      if (
        addUserBodyParam.value.office_mobile_number != "" &&
        addUserBodyParam.value.office_mobile_number < 9999
      ) {
        rules.value.office_mobile_number.show = true;
        isFormValid.value = false;
      }
      if (
        addUserBodyParam.value.password_confirmation == "" ||
        addUserBodyParam.value.password_confirmation == undefined
      ) {
        rules.value.confirm_password.show = true;
        isFormValid.value = false;
      }
      if (
        addUserBodyParam.value.password_confirmation !=
        addUserBodyParam.value.password
      ) {
        rules.value.password_match.show = true;
        isFormValid.value = false;
      }
      if (addUserBodyParam.value.role_id == "0") {
        rules.value.role.show = true;
        isFormValid.value = false;
      }
      if (
        addUserBodyParam.value.role_id == "2" &&
        addUserBodyParam.value.team_leader == 0
      ) {
        rules.value.team_leader.show = true;
        isFormValid.value = false;
      }
      if (
        (addUserBodyParam.value.role_id == "2" ||
          addUserBodyParam.value.role_id == "13" ||
          addUserBodyParam.value.role_id == "9") &&
        addUserBodyParam.value.policy_agent_id == 0
      ) {
        rules.value.underwritter.show = true;
        isFormValid.value = false;
      }
      if (
        (addUserBodyParam.value.role_id == "2" ||
          // addUserBodyParam.value.role_id == "4" ||
          addUserBodyParam.value.role_id == "13" ||
          addUserBodyParam.value.role_id == "9") &&
        roundRobinValue.value == "1"
      ) {
        if (
          addUserBodyParam.value.assign_car == 0 &&
          addUserBodyParam.value.assign_health == 0 &&
          addUserBodyParam.value.assign_misc == 0
        ) {
          rules.value.assign_leads_of.show = true;
          isFormValid.value = false;
        }
        if (assign_language_count.value == 0) {
          rules.value.assign_language.show = true;
          isFormValid.value = false;
        }
        if (
          addUserBodyParam.value.is_new == 0 &&
          addUserBodyParam.value.is_both == 0 &&
          addUserBodyParam.value.is_renewal == 0
        ) {
          rules.value.agent_type.show = true;
          isFormValid.value = false;
        }
      }
      if (
        [2,13,9,4].includes(addUserBodyParam.value.role_id)
      ) {
        if(addUserBodyParam.value.assign_misc == 1) {
          if(addUserBodyParam.value.misc_insurance_types.length == 0) {
            rules.value.assign_misc_insurance_types.show = true;
            isFormValid.value = false;
          }
        }
      }

    };
    const isCLick = ref(false)
    const addUser = () => {
      resetValidation();
      isFormValid.value = true;
      validateAddUser();

      if (isFormValid.value) {
        // assignAddUserParam();
        isCLick.value = true
        if (
          addUserBodyParam.value.is_both == 3 &&
          (roleValue.value == "2" ||
            roleValue.value == "13" ||
            roleValue.value == "9")
        ) {
          addUserBodyParam.value.is_new = 1;
          addUserBodyParam.value.is_renewal = 1;
        }
        console.log("addUserBodyParam", addUserBodyParam.value);

        UserService.addUser(addUserBodyParam.value)
        .then(() => {
          setTimeout(() => {
            isCLick.value = false
          }, 3000);
        })
        .catch((err) => {
            isCLick.value = false
          console.log(err)
        });
      }else{
            isCLick.value = false
        focusField()
        scrollPage();
      }
    };
    const scrollPage = () => {
      if(rules.value.name.show || rules.value.email.show || rules.value.password.show || 
      rules.value.password_match.show || rules.value.underwritter.show){
        window.scrollTo(0,0);
      }
    }
    // const assignAddUserParam = () => {
    //   addUserBodyParam = {
    //     name: name.value,
    //     email: email.value,
    //     role_id: roleValue.value,
    //     password: password.value,
    //     password_confirmation: confirmPassword.value,
    //     is_round_robin: roundRobinValue.value,
    //     policy_agent_id: underwritter.value,
    //     assign_en_leads: addUserBodyParam.assign_en_leads,
    //     assign_ar_leads: addUserBodyParam.assign_ar_leads,
    //     is_new: addUserBodyParam.is_new > 0 ? "1" : "0",
    //     is_renewal: addUserBodyParam.is_renewal > 0 ? "1" : "0",
    //     is_llr: addUserBodyParam.is_llr > 0 ? "1" : "0",
    //     is_renewal_deals: addUserBodyParam.is_renewal_deals == 1 ? "1" : "0",
    //     assign_car: addUserBodyParam.assign_car,
    //     assign_health: addUserBodyParam.assign_health,
    //     assign_misc: addUserBodyParam.assign_misc,
    //     status: 1,
    //     team_leader: teamLeader.value,
    //     skip_round: addUserBodyParam.skip_round,
    //     is_both: addUserBodyParam.is_both,
    //     office_mobile_number: addUserBodyParam.office_mobile_number,
    //     other_mobile_number: addUserBodyParam.other_mobile_number,
    //   };
    // };
    const isShowOptions = computed(() => {
      return roleValue.value == "2" ||
        roleValue.value == "13" ||
        roleValue.value == "9" ||
        roleValue.value == "4"
        ? true
        : false;
    });

    const isUnderwritter = computed(() => {
      return roleValue.value == "4" ? true : false;
    });

    const isShowRoundRobin = computed(() => {
      return roundRobinValue.value == "1" ? true : false;
    });

    const isShowRenewal = computed(() => {
      return renewalValue.value == "1" ? true : false;
    });
    const isPassword = ref(true);

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        nameRef()
      }, 500);
    }

    const nameRef = () => {
      const nameref:any = inst?.refs?.nameref
        nameref.focus() 
    }

    const emailRef = () => {
      const emailRef:any = inst?.refs?.emailRef
        emailRef.focus() 
    }

    const official_emailRef = () => {
      const official_emailRef:any = inst?.refs?.official_emailRef
        official_emailRef.focus() 
    }

    const passwordRef = () => {
      const passwordRef:any = inst?.refs?.passwordRef
        passwordRef.focus() 
    }

    const confirmRef = () => {
      const confirmRef:any = inst?.refs?.confirmRef
        confirmRef.focus() 
    }

    const roleRef = () => {
      const roleRef:any = inst?.refs?.roleRef
        roleRef.focus() 
    }

    const underwriterRef = () => {
      const underwriterRef:any = inst?.refs?.underwriterRef
        underwriterRef.focus() 
    }

    const teamLeaderRef = () => {
      const teamLeaderRef:any = inst?.refs?.teamLeaderRef
        teamLeaderRef.focus() 
    }

    const carRef = () => {
      const carRef:any = inst?.refs?.carRef
        carRef.focus() 
    }

    const englishRef = () => {
      const englishRef:any = inst?.refs?.englishRef
        englishRef.focus() 
    }

    const focusField = () => {
      const { name, email, official_email, password, password_match, role } = rules.value

      if(name.show) {
        nameRef()
      }

      if(!name.show && email.show) {
        emailRef()
      }

      if(!name.show && !email.show && official_email.show) {
        official_emailRef()
      }

      if(!name.show && !email.show && !official_email.show && password.show) {
        passwordRef()
      }

      if(!name.show && !email.show && !official_email.show && !password.show && password_match.show) {
        confirmRef()
      }

      if(!name.show && !email.show && !official_email.show && !password.show && !password_match.show && role.show) {
        roleRef()
      }

      if([2, 9].includes(addUserBodyParam.value.role_id)) {
        salesAgent()
      }

      if(addUserBodyParam.value.role_id === 4) {
        underwriter()
      }
    }

    const salesAgent = () => {
      const  { underwritter, team_leader, assign_leads_of, assign_language } = rules.value 

      if(underwritter.show && addUserBodyParam.value.is_underwriter === 0) {
        underwriterRef()
      }

      if(!underwritter.show && team_leader.show) {
        teamLeaderRef()
      }

      if(!underwritter.show && !team_leader.show && assign_leads_of.show) {
        carRef()
      }

      if(!underwritter.show && !team_leader.show && !assign_leads_of.show && assign_language.show) {
        englishRef()
      }
    }

    const underwriter = () => {
      const  { assign_leads_of } = rules.value 

      if(assign_leads_of.show) {
        carRef()
      }
    }
    
    const otherInsuranceTypesList:any = computed(() => {
      return [
            { id: 4, name: 'Home' },
            { id: 7, name: 'Jet Ski' },
            { id: 8, name: 'Pet' },
            { id: 3, name: 'Travel' },
            { id: 9, name: 'Wedding' },
            { id: 10, name: 'Yacht' },
        ]
    });

    return {
      isMiscCheck,
      otherInsuranceTypesList,
      // errorMessage,
      // value,
      isCLick,
      focusField,
      toFirst,
      role,
      isPassword,
      addUserBodyParam,
      onRoleChange,
      addUser,
      onInRoundRobin,
      onChangePolicyAgent,
      onChangeEng,
      isShowOptions,
      isUnderwritter,
      roleValue,
      isShowRoundRobin,
      roundRobinValue,
      isShowRenewal,
      renewalValue,
      onChangeRenewal,
      onChangeStatus,
      onChangeNew,
      onChangeAr,
      onChangeLlr,
      onChangeDeals,
      onChangeCar,
      onChangeHealth,
      onChangeMisc,
      onChangeBoth,
      selfDeal,
      disableRenewalDom,

      email,
      emailError,
      official_email,
      official_emailError,
      password,
      passwordError,
      name,
      NameError,
      confirmPassword,
      confirmPasswordError,

      rules,
      underwritter,
      is_llr,
      checkPassword,
      isFormValid,
      onChangeTeamLeader,
      teamLeader,
      onChangeAssignedLeads,
    };
  }
});
